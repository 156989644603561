import React, { useContext, useEffect, useState } from 'react'
import { DashboardLayout } from '../../Layout/DashboardLayout'
import CardBox from '../../Layout/CardBox'
import DataTableApp from '../../Layout/DataTableApp'
import { Badge, ButtonGroup, Card, CardBody, CardHeader, Col, FormGroup, Label, Row } from 'reactstrap'
import { useQuery } from '@apollo/client'
import { AlertApp } from '../../Global/AlertApp'

// apollo
import { GET_ALL_BUSINESS_PARTNER } from '../../../graphql/QueryManager'
import ActionButtonsForRows from '../../Layout/ActionButtonsForRows'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { AuthContext } from '../../../AuthContext'
const MyPortfolio = () => {
    const { user: userContext } = useContext(AuthContext)
    const [dataList, setDataList] = useState([])
    const [filterList, setFilterList] = useState([])

    const { loading, data, error } = useQuery(GET_ALL_BUSINESS_PARTNER, {
        fetchPolicy: 'cache-and-network',
        variables: {
            code: '',
            type: 'C'
        }
    })

    useEffect(() => {
        if (!loading) {
            if (error) {
                AlertApp({ type: 'toastError', message: error })
            }
            let list = []

            if (data.allBusinessPartners) {
                data.allBusinessPartners.map((element) => {
                    let buttons = <ActionButtonsForRows
                        baseUrl={'crm/activities'}
                        element={element}
                        scheduleSalesVisitButton
                    />

                    let cloneElement = { ...element }
                    cloneElement.actions = buttons
                    return list.push(cloneElement)
                })
            }
            setDataList(list)
            setFilterList(list)
        }
    }, [loading, data, error])

    const columns = [
        {
            name: '#',
            selector: row => `${row.cardCode}`,
            width: '80px',
        },
        {
            name: 'Razon social',
            selector: row => `${row.cardName}`,
        },
        {
            name: 'RFC',
            selector: row => row.licTradNum,
        },
        {
            name: 'Territorio',
            selector: row => row.territry.descript,
        },
        {
            name: 'Industria',
            selector: row => row.industry.indName,
        },
        {
            name: 'División',
            selector: row => row.u_Branch,
            width: '80px',
        },
        {
            name: 'Sucursal',
            selector: row => row.u_Branch,
            width: '80px',
        },
        {
            name: 'Vendedor',
            selector: row => row.salesPerson.slpName,
        },
        {
            name: '',
            selector: row => row.blocked ? <Badge color={"danger"} >Inactivo</Badge> : <></>,
        },
        {
            name: '',
            selector: row => row.actions
        },
    ]
    const addButtonsTools = () => {
        return <>
            <ButtonGroup>
                {(userContext.Rol === 4 || userContext.Rol === 1) && (
                    <a className="btn btn-secondary" onClick={() => setFilterList(dataList)}>
                        <FontAwesomeIcon icon={"filter-circle-xmark"} />{" Limpiar filtros"}
                    </a>
                )}
                {(userContext.Rol === 4 || userContext.Rol === 1) && (
                    <a className="btn btn-secondary" onClick={() => setFilterList(dataList.filter((element) => element.slpCode === userContext.SalesPersonCode))}>
                        <FontAwesomeIcon icon={"address-book"} />{" Mis clientes"}
                    </a>
                )}
            </ButtonGroup>
        </>
    }
    const ToogleDetailReceipt = ({ data }) => {
        return <Row>
            <Col md={{ size: 8, offset: 1 }}>
                <div class="col-12 col-sm-6 col-md-4 d-flex align-items-stretch flex-column">
                    <div class="card bg-light d-flex flex-fill">
                        <div class="card-body pt-0">
                            <div class="row">
                                <div class="col-7">
                                    <p class="text-muted text-sm"><b>Contacto: </b> {`${data.cntctPrsn ?? 'Sin contacto'}`} </p>
                                    <ul class="ml-4 mb-0 fa-ul text-muted">
                                        <li class="small"><span class="fa-li"><i class="fas fa-lg fa-building"></i></span> Direccion: {`${data.address}, C.P. ${data.zipCode}`}</li>
                                        <li class="small"><span class="fa-li"><i class="fas fa-lg fa-phone"></i></span> Telefono #: {`${data.phone1}`}</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </Col>
        </Row>
    }
    return (
        <DashboardLayout>
            <CardBox
                title={"Mis clientes"}
                loading={loading}
                actionTools={addButtonsTools()}
                content={<>
                    <Row>
                        {

                        }
                    </Row>
                    <Row>
                        <Col>
                            <DataTableApp
                                data={filterList}
                                columns={columns}
                                dense
                                filter
                                expandableRows
                                expandableRowsComponent={ToogleDetailReceipt}

                            />
                        </Col>
                    </Row>
                </>}
            />
        </DashboardLayout>
    )
}

export default MyPortfolio