import React, { useContext, useEffect, useRef, useState } from 'react'
import { DashboardLayout } from '../../Layout/DashboardLayout'
import CardBox from '../../Layout/CardBox'
import { Badge, Col, Row } from 'reactstrap'
import InputApp from '../../Layout/InputApp'
import { FormProvider, useForm } from 'react-hook-form'
import { CurrencyCodesTypesSBO, DivisionTypeOptions, ListOfOpptStatesSBO, ListOfOpptStatesValuesSBO } from '../../../Types'
import { useLazyQuery, useMutation, useQuery } from '@apollo/client'
import DataTableApp from '../../Layout/DataTableApp'
import ButtonApp from '../../Layout/ButtonApp'
import { useNavigate, useParams } from 'react-router-dom'
import { AlertApp } from '../../Global/AlertApp'
import ChooseFromListModal from '../../Global/ChooseFromListModal'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import moment from 'moment'
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { FIND_BO_ITEM, GET_ALL_SALES_ITEMS } from '../../../graphql/Catalogs/BoItems'
import { ADD_QUOTATION_OPPORTUNITY_CONTENT, CREATE_QUOTATION_OPPORTUNITY, DELETE_QUOTATION_OPPORTUNITY_CONTENT, GET_ONE_QUOTATION_OPPORTUNITY, UPDATE_QUOTATION_OPPORTUNITY } from '../../../graphql/Catalogs/Opportunities'
import { AuthContext } from '../../../AuthContext'

// apollo
import { GET_ALL_BRANCHS, GET_ALL_BUSINESS_PARTNER, GET_ALL_INTEREST_LEVEL, GET_ALL_SALES_PERSONS, GET_BUSINESS_PARTNER, GET_BUSINESS_PARTNER_CONTACT_PERSONS } from '../../../graphql/QueryManager'

const SalesOpportunityNew = () => {
    const { id: _id, show, card: _cardCode, type: _cardType } = useParams()
    const { user: _userContext } = useContext(AuthContext)
    const navigate = useNavigate()
    const [opportunity, setOpportunity] = useState({})
    const [chooseFromList, setChooseFromList] = useState(false)
    const [chooseFromListItem, setChooseFromListItem] = useState(false)
    const [cardList, setCarList] = useState([])
    const [itemList, setItemList] = useState([])
    const [businessPartner, setBusinessPartner] = useState({})
    const [boItem, setBoItem] = useState({})
    const [optOfSalesPersons, setOptOfSalesPersons] = useState([])
    const [optOfContactPerson, setOptOfContactPerson] = useState([])
    const [optOfInterestLevel, setOptOfInterestLevel] = useState([])
    const [optOfBranchs, setOptOfBranchs] = useState([])
    const [contentItems, setContentItems] = useState([])
    const [radioValue, setRadioValue] = useState(1)
    const [quoteSummaries, setQuoteSummaries] = useState({ volume: 0, subtotal: 0 })
    const validationSchema = Yup.object().shape({
        start: Yup.date().required('La fecha de inicio es requerida'),
        title: Yup.string().required('El titulo oportunidad es obligatorio'),
        currency: Yup.string().required('La moneda del documento es obligatoria'),
        type: Yup.string().default("S"),
        cardCode: Yup.string().required('El Código de socio de negocio es obligatorio'),
        cardName: Yup.string().required('El Nombre de socio de negocio es obligatorio'),
        slpCode: Yup.number().required('El empleado de ventas es requerido'),
        division: Yup.string().required('Seleccionar una división'),
        intRate: Yup.number().required('Seleccionar una opcion'),
        branch: Yup.string().required('Se debe seleccionar una sucursal'),
        cprCode: Yup.string().notRequired(),
        indCode: Yup.number().notRequired(),
        memo: Yup.string().notRequired(),
        quantity: Yup.string().notRequired(),
        itemCode: Yup.string().notRequired(),
        itemName: Yup.string().notRequired(),
        scanner: Yup.string().notRequired(),
    })

    const { handleSubmit, control, methods, getValues, setValue, setFocus, setError, clearErrors, reset } = useForm({ resolver: yupResolver(validationSchema) })
    const { loading, data, error, refetch } = useQuery(GET_ONE_QUOTATION_OPPORTUNITY, {
        fetchPolicy: 'cache-and-network',
        variables: {
            id: _id ? parseInt(_id) : 0
        }
    })
    const { loading: loadingSalesPerson, error: errorSalesPerson, data: dataSalesPerson } = useQuery(GET_ALL_SALES_PERSONS, {
        fetchPolicy: 'cache-and-network',
    })
    const { loading: loadingInterestLevel, error: errorInterestLevel, data: dataInterestLevel } = useQuery(GET_ALL_INTEREST_LEVEL, {
        fetchPolicy: 'cache-and-network',
    })
    const { loading: loadingBranchs, error: errorBranchs, data: dataBranchs } = useQuery(GET_ALL_BRANCHS, {
        fetchPolicy: 'cache-and-network',
    })
    const { loading: loadingDataCard, data: dataCard, error: errorCard } = useQuery(GET_BUSINESS_PARTNER, {
        fetchPolicy: 'cache-and-network',
        variables: {
            code: _cardCode ? _cardCode : '',
            type: _cardType ? _cardType : ''
        }
    })
    
    const { loading: loadingContacPerson, error: errorContacPerson, data: dataContacPerson } = useQuery(GET_BUSINESS_PARTNER_CONTACT_PERSONS, {
        fetchPolicy: 'cache-and-network',
        variables: {
            code: opportunity.cardCode ? opportunity.cardCode : _cardCode ? _cardCode : ''
        }
    })
    const { loading: loadingItems, error: errorItems, data: dataItems } = useQuery(GET_ALL_SALES_ITEMS)

    const [getBusinessPartner, { loading: loadingCard }] = useLazyQuery(GET_BUSINESS_PARTNER)
    const [getAllBusinessPartner, { loading: loadingAllCadrs }] = useLazyQuery(GET_ALL_BUSINESS_PARTNER)
    const [getContacPersons] = useLazyQuery(GET_BUSINESS_PARTNER_CONTACT_PERSONS)
    const [getItem] = useLazyQuery(FIND_BO_ITEM)

    const [create, { loading: onBeginCreate }] = useMutation(CREATE_QUOTATION_OPPORTUNITY)
    const [update, { loading: onBeginUpdate }] = useMutation(UPDATE_QUOTATION_OPPORTUNITY)
    const [addRow, { loading: onAddRow }] = useMutation(ADD_QUOTATION_OPPORTUNITY_CONTENT)
    const [deleteRow, { loading: onDeleteRow }] = useMutation(DELETE_QUOTATION_OPPORTUNITY_CONTENT)

    useEffect(() => {
        if (!loading)
            if (!_id) {

                setValue("start", moment(new Date()).format("yyyy-MM-DD"), { shouldDirty: true })
                setValue('currency', "MXP")
                setValue('priceType1', true)
                setValue('intRate', 1)
                setValue('division', "DAS")
                setValue('status', "O")
                setQuoteSummaries({
                    volume: 0,
                    subtotal: 0
                })
                if (_userContext.SalesPersonCode !== -1) {
                    setValue('slpCode', _userContext.SalesPersonCode)
                }
            } else {
                if (error) {
                    AlertApp({ type: 'errorToast', message: error })
                } else {
                    setOpportunity(data.oneQuotationOpportunity)
                    reset(data.oneQuotationOpportunity)
                    const list = []
                    let index = 0;
                    data.oneQuotationOpportunity.wspOpptItems.map((element) => {
                        let cloneElement = { ...element }

                        cloneElement.inputQuantity = <input type={"number"} id={`rowQuantity_${cloneElement.id}_${cloneElement.line}`} className={"form-control form-control-sm"} defaultValue={cloneElement.quantity} readOnly={readOnlyRows}
                            onKeyDown={(e) => {
                                if (e.key === "Tab" || e.key === "Enter") {
                                    e.preventDefault()
                                    onHandlerUpdateRow(cloneElement, index)
                                }
                            }} />
                        cloneElement.inputValue = <input type={"number"} id={`rowValue_${cloneElement.id}_${cloneElement.line}`} className={"form-control form-control-sm"} defaultValue={parseFloat(cloneElement.price).toFixed(2)} prefix={cloneElement.currency} readOnly={readOnlyRows}
                            onKeyDown={(e) => {
                                if (e.key === "Tab" || e.key === "Enter") {
                                    e.preventDefault()
                                    onHandlerUpdateRow(cloneElement, index)
                                }
                            }} />
                        cloneElement.saveButton = <button type={"button"} className={'btn btn-danger btn-sm'} onClick={() => onHandlerDeleteRow(cloneElement)} ><FontAwesomeIcon icon="fa-solid fa-trash" /></button>
                        list.push(cloneElement)
                        index++
                    })
                    setContentItems(list)
                    setQuoteSummaries({
                        volume: list.length > 0 ? list.reduce((n, { quantity }) => n + quantity, 0) : 0,
                        subtotal: list.length > 0 ? list.reduce((n, { price, quantity }) => n + (price * quantity), 0) : 0
                    })

                }
            }
    }, [_id, loading, error, data])

    useEffect(() => {
        if (!loadingSalesPerson) {
            if (errorSalesPerson) {
                AlertApp({ type: 'errorToast', message: errorSalesPerson })
            }
            let list = []
            if (dataSalesPerson) {

                dataSalesPerson.allSalesPersons.map((element) => {
                    return list.push({
                        value: element.slpCode,
                        label: element.slpName,
                    })
                })

            }
            setOptOfSalesPersons(list)
        }
    }, [loadingSalesPerson, errorSalesPerson, dataSalesPerson])
    useEffect(() => {
        if (!loadingInterestLevel) {
            if (errorInterestLevel) {
                AlertApp({ type: 'errorToast', message: errorInterestLevel })
            }
            let list = []
            if (dataInterestLevel) {

                dataInterestLevel.allInterestLevel.map((element) => {
                    return list.push({
                        value: element.num,
                        label: element.descript,
                    })
                })

            }
            setOptOfInterestLevel(list)
        }
    }, [loadingInterestLevel, errorInterestLevel, dataInterestLevel])
    useEffect(() => {
        if (!loadingBranchs) {
            if (errorBranchs) {
                AlertApp({ type: 'errorToast', message: errorBranchs })
            }
            let list = []
            if (dataBranchs) {

                dataBranchs.allBranches.map((element) => {
                    return list.push({
                        value: element.code,
                        label: element.code,
                    })
                })

            }
            setOptOfBranchs(list)
        }
    }, [loadingBranchs, errorBranchs, dataBranchs])
    useEffect(() => {
        if (!loadingItems) {
            if (errorItems) {
                AlertApp({ type: 'errorToast', message: errorItems })
            }

            if (dataItems) {

                setItemList(dataItems.allSItems)

            }
        }
    }, [loadingItems, errorItems, dataItems])
    useEffect(() => {
        if ((!loadingContacPerson && opportunity.cardCode) ||  (!loadingContacPerson && _cardCode)) {
            if (errorContacPerson) {
                AlertApp({ type: 'errorToast', message: errorContacPerson })
            }
            let list = []
            if (dataContacPerson.allContactPersonByCard) {

                dataContacPerson.allContactPersonByCard.map((element) => {
                    return list.push({
                        value: element.cntctCode,
                        label: element.name,
                    })
                })
            }
            setOptOfContactPerson(list)
        }
    }, [loadingContacPerson, errorContacPerson, dataContacPerson, opportunity,_cardCode])
    useEffect(() => {
        if (!loadingDataCard) {
            if (errorCard) {
                AlertApp({ type: 'errorTotast', message: errorCard })
            } else {
                if (dataCard.oneBusinessPartner) {
                    setBusinessPartner(dataCard.oneBusinessPartner)

                    setValue("cardCode", dataCard.oneBusinessPartner.cardCode, { shouldDirty: true })
                    setValue("cardType", dataCard.oneBusinessPartner.cardType, { shouldDirty: true })
                    setValue("cardName", dataCard.oneBusinessPartner.cardName, { shouldDirty: true })
                    setValue("slpCode", dataCard.oneBusinessPartner.slpCode, { shouldDirty: true })
                    setValue("territryID", dataCard.oneBusinessPartner.territory, { shouldDirty: true })
                    setValue("indCode", dataCard.oneBusinessPartner.industryC, { shouldDirty: true })
                    setValue("currency", dataCard.oneBusinessPartner.currency, { shouldDirty: true })
                    setValue('branch', dataCard.oneBusinessPartner.u_Branch, { shouldDirty: true })
                    setValue('cprCode', dataCard.oneBusinessPartner.cntctPrsn, { shouldDirty: true })
                    if (dataCard.oneBusinessPartner.u_Division)
                        setValue('division', element.u_Division, { shouldDirty: true })
                }
            }
        }
    }, [loadingDataCard, errorCard, dataCard])
    useEffect(() => {
        if (boItem.itemCode) {
            setValue('scanner', boItem.itemCode, { shouldDirty: true })
            setValue('itemName', boItem.itemName, { shouldDirty: true })
            setValue('quantity', "1", { shouldDirty: true })

        } else {
            clearErrors('scanner')
            clearErrors('itemName')
            clearErrors('quantity')
            setValue('scanner', "")
            setValue('itemName', "")
            setValue('quantity', "")

        }
    }, [boItem])

    const isWaiting = loadingSalesPerson || loadingCard || loadingAllCadrs
    const readOnlyRows = !(_userContext.Rol === 4 || _userContext.Rol === 1)

    const columns = [
        {
            name: '#',
            selector: row => row.id,
            width: '80px',
        },
        {
            name: 'SKU',
            selector: row => row.itemCode,
            width: _id ? '100px' : '80px',
        },
        {
            name: 'Descripcion',
            selector: row => row.itemName,

        },
        {
            name: 'Cantidad',
            selector: row => row.inputQuantity,
        },
        {
            name: 'Precio Unitario',
            selector: row =>row.inputValue,
        },
        {
            name: 'Total',
            selector: row => row.lineTotal,
        },
        {
            name: '',
            selector: row => row.saveButton,
        },
    ]
    const columnCards = [
        {
            name: '#',
            selector: row => `${row.cardCode}`,
            width: '80px',
        },
        {
            name: 'Razon social',
            selector: row => `${row.cardName}`,
        },
        {
            name: 'RFC',
            selector: row => row.licTradNum,
        },
        {
            name: 'Territorio',
            selector: row => row.territry.descript,
        },
        {
            name: 'Industria',
            selector: row => row.industry.indName,
        },
        {
            name: 'Status',
            selector: row => row.frozenFor,
        },
        {
            name: '',
            selector: row => <button
                id={`selectButton${row.id}`}
                key={`selectButton${row.id}`}
                onClick={() => OnSelectRow(row)}
                className='btn btn-info btn-sm'
            ><FontAwesomeIcon icon="fa-solid fa-share" /></button>
        },
    ]
    const columnItems = [
        {
            name: '#',
            selector: row => `${row.itemCode}`,
            width: '80px',
        },
        {
            name: 'Descripcion',
            selector: row => `${row.itemName}`,
        },
        {
            name: 'Unidad',
            selector: row => row.salUnitMsr,
            width: '80px',
        },
        {
            name: 'Paquete',
            selector: row => row.salPackMsr,
            width: '80px',
        },
        {
            name: 'Grupo',
            selector: row => row.itemGroup.itmsGrpNam,
        },

        {
            name: '',
            selector: row => <button
                id={`selectButton${row.id}`}
                key={`selectButton${row.id}`}
                onClick={() => OnSelectRowItem(row)}
                className='btn btn-info btn-sm'
            ><FontAwesomeIcon icon="fa-solid fa-share" /></button>
        },
    ]
    const radioOptions = [
        { label: "Una sola exhibicion", value: 1 },
        { label: "Convenio", value: 2 },
    ];
    const onChooseFromList = async () => {
        try {
            const result = await getAllBusinessPartner({
                variables: {
                    code: '',
                    type: 'C'
                }
            })
            setCarList(result.data.allBusinessPartners)
            setChooseFromList(!chooseFromList)
        } catch (error) {
            AlertApp({ type: 'errorToast', message: error })
        }
    }
    const onHandlerFindCode = async (event) => {
        event.preventDefault()
        try {

            if (event.target.name === "cardCode") {
                const code = getValues('cardCode')
                const result = await getBusinessPartner({
                    variables: {
                        code,
                        type: 'C'
                    }
                })

                if (result.data.oneBusinessPartner) {
                    onSetCard(result.data.oneBusinessPartner)
                }
            }
            if (event.target.name === "cardName") {
                const code = getValues('cardName')
                const result = await getAllBusinessPartner({
                    variables: {
                        code: code,
                        type: 'C'
                    }
                })

                if (result.data.allBusinessPartners) {
                    const list = result.data.allBusinessPartners

                    if (list.length === 1) {
                        onSetCard(list[0])
                        setBusinessPartner(list[0])
                    } else {
                        setCarList(list)
                        setChooseFromList(!chooseFromList)
                    }
                }
            }

        } catch (error) {
            AlertApp({ type: 'error', message: error })
        }
    }
    const OnSelectRow = async (row) => {
        try {
            onSetCard(row)
        } catch (error) {
            AlertApp({ type: 'error', message: error })
        } finally {
            setChooseFromList(!chooseFromList)
        }
    }
    const OnSelectRowItem = async (row) => {
        try {
            setBoItem(row)
        } catch (error) {
            AlertApp({ type: 'error', message: error })
        } finally {
            setChooseFromListItem(!chooseFromListItem)
        }
    }
    const onSetCard = async (element) => {
        setValue("cardCode", element.cardCode, { shouldDirty: true })
        setValue("cardName", element.cardName, { shouldDirty: true })
        if (_userContext.SalesPersonCode !== -1)
            setValue("slpCode", element.slpCode, { shouldDirty: true })
        setValue('branch', element.u_Branch, { shouldDirty: true })
        setBusinessPartner(element)
        if (element.u_Division)
            setValue('division', element.u_Division, { shouldDirty: true })
        await onGetAllContacPersons(element.cardCode)
        setFocus("scanner")
    }
    const onGetAllContacPersons = async (cardCode) => {
        const contacs = await getContacPersons({
            variables: { code: cardCode }
        })

        let list = []
        if (contacs.data.allContactPersonByCard) {

            contacs.data.allContactPersonByCard.map((element) => {
                return list.push({
                    value: element.cntctCode,
                    label: element.name,
                })
            })
        }
        if (list.length > 0) {
            setValue('cprCode', list[0].value, { shouldDirty: true })
        }
        setOptOfContactPerson(list)
    }
    const HandlerFinItem = async (event) => {
        try {
            event.preventDefault()
            const scanner = getValues("scanner")
            const result = await getItem({
                variables: {
                    find: scanner
                }
            })

            if (result.data.findBoItem) {
                if (result.data.findBoItem.length > 0) {
                    setBoItem(result.data.findBoItem[0])
                    setValue('itemName', result.data.findBoItem[0].itemName)
                    setFocus("quantity")
                } else {
                    AlertApp({ type: 'error', message: `No se encontro el articulo ${scanner}` })
                }
            }
        } catch (error) {
            AlertApp({ type: 'error', message: error })
        }
    }
    const onHandlerUpdateRow = async (element, index) => {
        try {
            let list = []
            console.log(list)
            const quantity = parseFloat(document.getElementById(`rowQuantity_${element.id}_${element.lineNum}`).value)
            const price = parseFloat(document.getElementById(`rowValue_${element.id}_${element.lineNum}`).value)

            list[index] = { ...list[index], quantity, price, lineTotal: quantity * price }
            // console.log(list)
            // setContentItems(list)

//             contentItems.map((element) => {
//                 let cloneElement = { ...element }
//                 if(cloneElement.id === element){
//                     cloneElement.quantity = quantity
//                     cloneElement.price = price
//                     cloneElement.lineTotal = quantity * price
//                 }
//                 list.push(cloneElement)
//             })
// setContentItems(list)
            setQuoteSummaries({
                volume: list.length > 0 ? list.reduce((n, { quantity }) => n + quantity, 0) : 0,
                subtotal: list.length > 0 ? list.reduce((n, { price, quantity }) => n + (price * quantity), 0) : 0
            })
        } catch (error) {
            AlertApp({ type: 'error', message: error })
        }
    }
    const onHandlerAddRow = async (event) => {
        try {
            if (boItem.itemCode) {
                const newRow = {
                    id: contentItems.length,
                    itemCode: boItem.itemCode,
                    itemName: boItem.itemName,
                    price: parseFloat(1),
                    quantity: parseFloat(getValues('quantity')),
                    inputQuantity: <input type={"number"} className={"form-control form-control-sm"} defaultValue={parseFloat(getValues('quantity'))} />,
                    inputValue: <input type={"number"} className={"form-control form-control-sm"} defaultValue={parseFloat(1)} readOnly={true} />,
                }

                setContentItems(prev => [...prev, newRow])
                if (_id) {

                    let input = {
                        id: `SalesOpportunities${contentItems.length}`,
                        db: 'GALBER',
                        itemCode: newRow.itemCode,
                        itemName: newRow.itemName,
                        quantity: newRow.quantity,
                        itemValue: newRow.price,
                        line: contentItems.length,
                        objType: 'SalesOppr',
                        docEntry: 0,
                        docNum: 0,
                        comments: '',
                    }
                    if (!readOnlyRows) {
                        input.itemValue = parseFloat(getValues('price'))
                    }
                    console.log(input)
                    var result = await addRow({
                        variables: {
                            id: parseInt(_id),
                            input,
                        },
                        refetchQueries: refetch
                    })

                    if (result.errors) {
                        AlertApp({ type: 'errorToast', message: result.errors })
                    }
                    if (result.data.addQuotationOpportunityContent)
                        AlertApp({ type: 'okToast', message: 'Operacion finalizada con exito' })
                }
                setBoItem({})
            } else {
                setError('scanner', { type: 'required', message: 'Se requiere el articulo' })
                setFocus('scanner')
            }
        } catch (error) {
            AlertApp({ type: 'errorToast', message: error })
            console.log(error)
        }
    }
    const onHandlerDeleteRow = async (element) => {
        try {
            if (_id) {
                const result = await deleteRow({
                    variables: {
                        id: parseInt(_id),
                        row: element.id
                    },
                    refetchQueries: refetch
                })

                if (result.errors)
                    AlertApp({ type: 'errorToast', message: result.errors })

                if (result.data.deleteQuotationOpportunityContent) {
                    AlertApp({ type: 'okToast', message: 'Operacion finalizada con exito' })
                }
            } else {

            }
        } catch (error) {
            AlertApp({ type: 'errorToast', message: error })
        }
    }
    const onSubmit = async (data) => {
        try {
            if (!onBeginCreate) {
                if (_id) {
                    let list = []
                    let index = 0;
                    contentItems.map((row) => {
                        list.push({
                            index: row.id,
                            quantity: parseFloat(document.getElementById(`rowQuantity_${row.id}_${row.line}`).value),
                            price:  parseFloat(document.getElementById(`rowValue_${row.id}_${row.line}`).value),
                        })
                        index++;
                    })

                    const result = await update({
                        variables: {
                            id: opportunity.id,
                            input: {
                                currency: getValues("currency"),
                                currentStage: parseInt(getValues("currentStage")),
                                reason: parseInt(getValues("numReason")) === 0 ? null : parseInt(getValues("numReason")),
                                status: ListOfOpptStatesValuesSBO[opportunity.status],
                                maxLocalTotal: list.length === 0 ? 1.0 : list.reduce((n, { price, quantity }) => n + (price * quantity), 0),
                                items: list,
                            }
                        }
                    })
                    if (result.errors) {
                        AlertApp({ type: 'errorToast', message: result.errors })
                    }
                    if (result.data.updateQuotationOpportunity) {
                        AlertApp({ type: 'okToast', message: 'Operacion finalizada con exito' })

                    }
                } else {
                    let list = []
                    let index = 0;
                    contentItems.map((row) => {
                        list.push({
                            id: `SalesOpportunities${index}`,
                            db: 'GALBER',
                            itemCode: row.itemCode,
                            itemName: row.itemName,
                            quantity: row.quantity,
                            itemValue: row.price,
                            line: index,
                            objType: 'SalesOppr',
                            docEntry: 0,
                            docNum: 0,
                            comments: '',
                        })
                        index++;
                    })

                    let input = {
                        title: data.title,
                        start: data.start,
                        predEnd: moment(data.start).add('days', 7),
                        division: data.division,
                        memo: getValues("memo"),
                        baseID: 2,
                        currentStage: 1,
                        cardCode: businessPartner.cardCode,
                        cardName: businessPartner.cardName,
                        cardType: businessPartner.cardType,
                        slpCode: parseInt(data.slpCode),
                        intRate: parseInt(data.intRate),
                        cloPrcnt: 0.0,
                        status: 'O',
                        reason: null,
                        stepId: 1,
                        currency: data.currency,
                        agreementType: radioValue,
                        branch: data.branch,
                        items: list
                    }
                    if (data.cprCode != "") {
                        input.cprCode = parseInt(data.cprCode)
                    }
                    const result = await create({
                        variables: {
                            input
                        }
                    })

                    if (result.data.createQuotationOpportunity) {
                        AlertApp({ message: 'Operacion finalizada con exito' })
                        navigate('/crm/QuotationOpportunities')
                    }
                }
            }
        } catch (error) {
            AlertApp({ type: 'errorToast', message: error })
            console.log(error)
        }
    }
    return (
        <DashboardLayout >
            <CardBox
                title={"Oportunidad"}
                loading={isWaiting || onBeginCreate || onBeginUpdate}
                footer
                btnSubmit={!show ? handleSubmit(onSubmit) : null}
                btnReturn={() => navigate("/crm/QuotationOpportunities")}
                content={<>
                    <FormProvider  {...methods}>
                        <form>
                            <Row>
                                <Col sm={3} >
                                    <InputApp name={"start"} label={"Fecha de inicio"} inputType='date' control={control} />
                                </Col>
                                <Col sm={5}>
                                    <InputApp name={"title"} label={"Objetivo"} placeholder={"Título o motivo de la solicitud"} autoFocus control={control} />
                                </Col>
                                <Col sm={4}>
                                    <Row>
                                        <Col sm={6}>
                                            <InputApp name={"currency"} label={"Moneda"} inputType={"select"} listOfOptions={CurrencyCodesTypesSBO} control={control} defaultValue={"Abierto"} changeAction={(e) => setOpportunity((prevState) => ({ ...prevState, currency: e.target.value }))} />
                                        </Col>
                                        <Col sm={6}>
                                            <InputApp name={"status"} label={"Status"} inputType={"select"} listOfOptions={ListOfOpptStatesSBO} control={control} defaultValue={"Abierto"} isReadOnly={true} />
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <hr />
                            <Row>
                                <Col sm={12}>
                                    <Row>
                                        <Col sm={4}>
                                            <InputApp name={"cardCode"} label={"Código de negocio"} control={control} placeholder={'Enter para buscar'} inputType={`${_id ? 'text' : 'textButton'}`} isReadOnly={_id}
                                                faIcon='fa-solid fa-list' buttonActionToolTip={`Lista de clientes`}
                                                onKeyDown={(e) => { if (e.key === 'Enter' || e.key === 'Tab') { onHandlerFindCode(e) } }} buttonAction={() => onChooseFromList()}
                                            />
                                        </Col>
                                        <Col sm={8}>
                                            <InputApp name={"cardName"} label={"Nombre de negocio"} control={control} placeholder={'Razón social'} faIcon='fa-solid fa-list' buttonActionToolTip={`Lista de clientes`} inputType={`${_id ? 'text' : 'textButton'}`} isReadOnly={_id}
                                                onKeyDown={(e) => { if (e.key === 'Enter') { onHandlerFindCode(e) } }} buttonAction={() => onChooseFromList()} />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm={6}>
                                            <InputApp name={"cprCode"} label={"Contacto"} inputType='select' listOfOptions={optOfContactPerson} control={control} />
                                        </Col>
                                        <Col sm={6}>
                                            <InputApp name={"branch"} label={"Sucursal"} control={control} inputType={"select"} listOfOptions={optOfBranchs} />
                                        </Col>
                                    </Row>
                                </Col>
                                <Col sm={12}>
                                    <Row>
                                        <Col>
                                            <InputApp name={"slpCode"} label={"Representate"} control={control} inputType={"select"} listOfOptions={optOfSalesPersons} isReadOnly={readOnlyRows} />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm={6}>
                                            <InputApp name={"division"} label={"Division"} inputType='select' listOfOptions={DivisionTypeOptions} control={control} />
                                        </Col>
                                        <Col sm={6}>
                                            <InputApp name={"intRate"} label={"Nivel de interes"} control={control} inputType={"select"} listOfOptions={optOfInterestLevel} />
                                        </Col>
                                    </Row>
                                    <Row className={`align-self-center`}>
                                        <Col sm={6} md={3}>
                                            <InputApp name={"agreementType"} inputType={"radioCheck"} label={"Tipo"} listOfOptions={radioOptions} control={control} radioState={radioValue} setRadioState={setRadioValue} />
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <hr />
                            <Row>
                                <Col sm={6} md={3}>
                                    <InputApp name={"scanner"} label={"Articulo"} inputType={"textButton"}
                                        placeholder={'Enter para buscar'} control={control} faIcon='fa-solid fa-list' buttonActionToolTip={`Lista de articulos`}
                                        buttonAction={() => setChooseFromListItem(!chooseFromListItem)}
                                        onKeyDown={(e) => { if (e.key === 'Enter' || e.key === 'Tab') { HandlerFinItem(e) } }} />
                                </Col>
                                <Col sm={6} md={5}>
                                    <InputApp name={"itemName"} label={"Descripcion"} control={control} isReadOnly={true} />
                                </Col>
                                <Col sm={6} md={2}>
                                    <InputApp name={"quantity"} label={"Cantidad"} inputType={"text"} placeholder={"Teclear cantidad"} control={control} />
                                </Col>
                                {!readOnlyRows && (
                                    <Col sm={6} md={2}>
                                        <InputApp name={"price"} label={"Precio"} inputType={"text"} placeholder={"Teclear precio unitario"} control={control} />
                                    </Col>
                                )}

                                <Col md={2} className={`align-self-center`}>
                                    <ButtonApp text={"Agregar"} additionalClass={"btn-md"} color="success" action={() => onHandlerAddRow()} actionButtonType={"button"} />
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={12} md={12} lg={12}>
                                    <DataTableApp
                                        columns={columns}
                                        data={contentItems}
                                        dense
                                        progressPending={onAddRow}
                                    />
                                </Col>
                                <Col sm={6} md={6} lg={6}>
                                    <InputApp name={"memo"} label={"Notas"} placeholder={"Basado en solicitud de precio especial"} inputType={"textarea"} control={control} />
                                </Col>
                                <Col sm={6} md={6} lg={6}>
                                    <p className="lead">{`Fecha de vencimiento ${moment(getValues('start')).add('days', 7).format("yyyy-MM-DD")}`}</p>
                                    <div className="table-responsive">
                                        <table className="table">
                                            <tbody>
                                                <tr>
                                                    <th>Volumen:</th>
                                                    <td>{`${quoteSummaries.volume}`}</td>
                                                </tr>
                                                <tr>
                                                    <th>Total:</th>
                                                    <td>{`${quoteSummaries.subtotal} ${_id ? opportunity.currency : getValues("currency")}`}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </Col>

                            </Row>

                        </form>
                    </FormProvider>

                </>}
            />
            <ChooseFromListModal
                chooseFromList={chooseFromList}
                setChooseFromList={setChooseFromList}
                data={cardList}
                columns={columnCards}
                filter={true}
                selectableRows={false}
                title={`Lista de socios comerciales`}
                size={"xl"} />
            <ChooseFromListModal
                chooseFromList={chooseFromListItem}
                setChooseFromList={setChooseFromListItem}
                data={itemList}
                columns={columnItems}
                filter={true}
                selectableRows={false}
                title={`Lista de articulos`}
                size={"xl"} />
        </DashboardLayout>
    )
}

export default SalesOpportunityNew